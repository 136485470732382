<template>
  <v-container class="fill-height secondary un-bg" fluid>
    <v-row no-gutters align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card flat class="elevation-3" tile>
          <v-toolbar color="primary" dark flat>
            <v-img max-height="40" max-width="40" src="/logo.png" />
            <v-toolbar-title
              class="text-uppercase font-weight-bold subtitle-2"
              style="padding: 0 3px;"
              >UNDSS SRS</v-toolbar-title
            >
            <v-spacer />
          </v-toolbar>
          <v-card-text class="d-flex">
            <v-icon x-large color="red">mdi-file-question</v-icon>
            <p class="pt-4 px-3">The page you are looking for is not found, please make sure you are using the right link and page name.</p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="primary"
              tile
              depressed
              @click="goLogin"
            >
              {{ $t("login.login") }}
            </v-btn>
            <v-btn
              text
              color="primary"
              tile
              depressed
              @click="goRegister"
            >
              {{ $t("login.register") }}
            </v-btn>
            <v-spacer />
            <v-btn
              color="primary"
              tile
              depressed
              @click="goBack"
            >
              {{ $t("general.goBack") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" justify="center">
      <copyright :size="64" />
    </v-row>
  </v-container>
</template>

<script>
import Copyright from "@/common/components/Copyright";

export default {
  name: "Page404",
  components: { Copyright },
  methods: {
    goBack() {
      this.$router.go(-2);
    },
    goLogin() {
      this.$router.replace({ name: "login" });
    },
    goRegister() {
      this.$router.replace({ name: "register" });
    },
  },
};
</script>
